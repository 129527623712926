<template>
    <v-app>
        <v-dialog
            v-model="dialog"
            max-width="1200px"
        >
            <v-card>
            <v-card-title>
                <span > Export Accountholder</span>
            </v-card-title>
            <v-card-subtitle class="ml-1">
                <span class="font-size-md"> </span>
            <v-divider></v-divider>
            </v-card-subtitle>
            <v-card-text>
                <div class="search-wrapper border-outline mb-5 px-3 py-5">
                    <v-row>
                        <v-col cols="12" md="2">
                            <v-menu
                                ref="menu"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    label="View from date"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                    outlined
                                    >
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    no-title
                                    :active-picker.sync="activePicker"
                                    min="1950-01-01"
                                ></v-date-picker>
                                </v-menu>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-menu
                                ref="menu"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    label="View up to date"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                    outlined
                                    >
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    no-title
                                    :active-picker.sync="activePicker"
                                    min="1950-01-01"
                                ></v-date-picker>
                                </v-menu>
                        </v-col>
                        <v-col cols="2">
                            <v-select
                                outlined
                                dense
                                label="Select Month"
                            ></v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-select
                                outlined
                                dense
                                label="Select Type"
                            ></v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-select
                                outlined
                                dense
                                label="Select State"
                            ></v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-select
                                outlined
                                dense
                                label="Payment Type"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            Search Field
                            <v-select
                                outlined
                                dense
                                label="Select Column"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            Conditions
                            <v-select
                                outlined
                                dense
                                label="Select Condition"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            Value
                            <v-select
                                outlined
                                dense
                                label="Value"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="7">
                            <v-btn
                                color="red darken-3"
                                class="text-white"
                            >
                                <i class="fa fa-calendar ml-1 text-white"></i>&nbsp; Select Fields
                            </v-btn>

                            <v-btn
                                color="purple darken-3"
                                class="text-white ml-3"
                            >
                                <i class="fa fa-filter ml-1 text-white"></i>&nbsp; Add Filter
                            </v-btn>

                            <v-btn
                                color="blue lighten-1"
                                class="text-white ml-3"
                            >
                                <i class="fa fa-save ml-1 text-white"></i>&nbsp; Save For Me
                            </v-btn>

                            <v-btn
                                color="green lighten-1"
                                class="text-white ml-3"
                            >
                                <i class="fa fa-save ml-1 text-white"></i>&nbsp; Save For Me
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="5" style="display:flex; justify-content: flex-end;">
                            <v-btn
                                color="orange darken-3"
                                class="text-white"
                            >
                                Search
                            </v-btn>

                            <v-btn
                                color="blue darken-4"
                                class="text-white ml-3"
                            >
                                Export To CSV
                            </v-btn>

                            <v-btn
                                color="purple darken-3"
                                class="text-white ml-3"
                            >
                                Export To Excel
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import AccountholderService from "@/services/user/accountholder/AccountholderService";

const accountHolder=new AccountholderService();

export default{
    data(){
        return{
            dialog: false,
            activePicker: '',
        }
    },
    methods:{
        exportUser(){
            this.openDialog();
        },
        openDialog(){
            this.dialog = true;
        },
        getExportFilters(){
            accountHolder
            .getExportFilters()
            .then((response) => {
            }).catch((err) => {
                
            });
        }
    },
    mounted(){
        this.getExportFilters();
    }
}
</script>
