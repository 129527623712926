<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} enroller</span>
          <hr>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row class="form-container">
            <v-col cols="12" sm="12" md="12">
              <label class="h6">Select enroller type<span class="text-danger">*</span></label>
                  <v-container class="px-2 mx-2" fluid row>
                    <v-checkbox  value="teacher" class="mr-3"
                                 v-model="roleSelected"
                                 label="Teacher">
                    </v-checkbox>
                    <v-checkbox   value="parent" class="mr-3"
                                  v-model="roleSelected"
                                  label="Parent">
                    </v-checkbox>
                    <v-checkbox  value="adult_learner" class="mr-3"
                                 v-model="roleSelected"
                                 label="Adult learner">
                    </v-checkbox>
              </v-container>
              <span class="text-danger" v-if="$v.roleSelected.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-select
                  label="Title" :error="$v.account_holder_profile.title.$error"
                  v-model="account_holder_profile.title"
                  :menu-props="{ top: true, offsetY: true }"
                  :items="titles"
                  item-text="name"
                  item-value="value"
                  dense
                  outlined>
                <template v-slot:label>
                Title <span class="text-danger">*</span>
              </template>
              </v-select>
              <span class="text-danger" v-if="$v.account_holder_profile.title.$error">This information is required</span>

            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field outlined  label="First name*" :error="$v.account_holder_profile.first_name.$error" dense
                            v-model="account_holder_profile.first_name">
                <template v-slot:label>
                  First name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.account_holder_profile.first_name.$error">This information is required</span>

            </v-col>
<!--            <v-col cols="12" sm="12" md="4">-->
<!--              <v-text-field outlined   label="Middle name" dense-->
<!--                            v-model="account_holder_profile.middle_name"-->
<!--              ></v-text-field>-->

<!--            </v-col>-->
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                  outlined label="Surname" dense
                   :error="$v.account_holder_profile.last_name.$error"
                  v-model="account_holder_profile.last_name"
              >
                <template v-slot:label>
                  Surname <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.account_holder_profile.last_name.$error">This information is required</span>


            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field outlined  dense @blur="checkDuplicateEmail" label="Email" :error="$v.account_holder_profile.email.$error"
                            v-model="account_holder_profile.email" onkeyup="copyEmailAddress()"
              >
                <template v-slot:label>
                 Email <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="font-weight-medium text-danger" v-if="messages">{{messages}}</span>
              <span class="text-danger" v-if="$v.account_holder_profile.email.$error">This information is required</span>
              <span class="text-danger" v-if="!$v.account_holder_profile.email.email">Email must be valid</span>

            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                  outlined  label="Phone"
                  dense
                  :error="$v.account_holder_profile.phone.$error"
                  v-model="account_holder_profile.phone"
              >
                <template v-slot:label>
                  Phone <span class="text-danger"></span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.account_holder_profile.phone.$error">This information is required</span>

            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                  outlined
                  dense
                  :error="$v.account_holder_profile.mobile.$error"
                  v-model="account_holder_profile.mobile"
              >
                <template v-slot:label>
                  Mobile <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.account_holder_profile.mobile.$error">This information is required</span>
            </v-col>

<!--            <v-col cols="12" sm="12" md="6">-->
<!--              <v-text-field-->
<!--                  outlined label="Work phone"-->
<!--                  -->
<!--                  v-model="account_holder_profile.work_phone"-->
<!--              ></v-text-field>-->
<!--            </v-col>-->
            <v-col cols="12" sm="12" md="4" v-if="currentUser && currentUser.access_type!='score'">
              <v-select label="SCORE" :error="$v.account_holder_profile.current_score_id.$error" v-model="account_holder_profile.current_score_id" dense outlined :items="scores" item-text="name" item-value="id">
              </v-select>
              <span class="text-danger" v-if="$v.account_holder_profile.current_score_id.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <label class="h6">Notification preference</label>
              <v-radio-group
                  v-model="account_holder_profile.notification_preference" row>
                <v-radio
                    label="Email"
                    value="email"
                    dense
                ></v-radio>
                <v-radio
                    label="Postal"
                    value="postal"
                    dense
                ></v-radio>
              </v-radio-group>

            </v-col>
<!--            <v-col cols="12" sm="12" md="12">-->
<!--              <v-autocomplete-->
<!--                  @change="resetUserSearch"-->
<!--                  v-model="account_holder_profile.school_account_ids"-->
<!--                  :search-input.sync="userSearch"-->
<!--                  :items="school_accounts"-->
<!--                  :loading="isLoading"-->
<!--                  item-text="display_text"-->
<!--                  item-value="id"-->
<!--                  label="School"-->
<!--                  multiple-->
<!--                  small-chips-->
<!--                  deletable-chips-->
<!--                  clearable-->
<!--                  outlined-->

<!--              >-->
<!--                <template v-slot:no-data>-->
<!--                  <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                      <v-list-item-title>-->
<!--                        <strong>Lookup by name or unique IDs </strong>-->
<!--                      </v-list-item-title>-->
<!--                    </v-list-item-content>-->
<!--                  </v-list-item>-->
<!--                </template>-->
<!--              </v-autocomplete>-->
<!--            </v-col>-->
            <v-col cols="12" sm="12" md="12">
              <v-checkbox v-model="account_holder_profile.auto_generate">
                <template v-slot:label>
                  <span class="text-left font-weight-medium">
                    Automatically create a random password
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="12" md="6" v-if="!account_holder_profile.auto_generate">
              <v-text-field type="password" dense outlined label="New password" :error="$v.account_holder_profile.new_password.$error"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            v-model="account_holder_profile.new_password">
                <template v-slot:label>
                  New password <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.account_holder_profile.new_password.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <p><b>When creating a new profile, the Enroller will be sent a confirmation email containing their new password.</b>
              </p>
              <v-checkbox v-model="account_holder_profile.send_email">
                <template v-slot:label>
                  Send confirmation email to a different email address
                </template>
              </v-checkbox>
              <v-text-field
                  v-if="account_holder_profile.send_email"
                  dense
                  v-model="emails"
                  prepend-inner-icon="mdi-email"
                  hide-selected outlined
                  >
                  <template v-slot:label>
                    Email address to send confirmation email <span class="text-danger">*</span>
                  </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.emails.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <span class="font-weight-medium subtitle-1">Status</span>
              <v-switch
                  v-model="account_holder_profile.is_active"
                  label="Status"
                  :label="account_holder_profile.is_active? 'Active':'Inactive'"
                  hide-details
              ></v-switch>
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn
                  class="mx-2 cancel-btn"
                  text
                  x-large
                  @click="closeDialog">
                Cancel
              </v-btn>
              <v-btn
                  dark
                  x-large
                  :disabled="buttonDisabled"
                  @click="createAccountholder()"
                  :loading="loading"

              >
                Save
              </v-btn>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>



      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import UserAddressService from "@/services/user/UserAddressService";
import StateService from "@/services/setting/state/StateService";
import CountryService from "@/services/country/CountryService";
import {email, required, requiredIf} from "vuelidate/lib/validators";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";

const score=new ScoreService();
const accountholder=new AccountholderService();
const country=new CountryService();
const state =new StateService;
const account_holder_profile=new UserAddressService;
export default {
  validations:{
    account_holder_profile:{
      title:{},
      first_name:{required},
      last_name: {required},
      phone: {},
      mobile: {required},
      email: {required,email},
      new_password: {
        required: requiredIf(function () {
          return this.account_holder_profile.auto_generate == false;
        })
      },
      current_score_id: {
        required: requiredIf(function (nestedModel) {
          return this.currentUser.access_type != 'score';
        })
      }
    },
    emails:{
      required: requiredIf(function(){
        return (this.account_holder_profile.send_email)
      })
    },
    roleSelected:{required},
  },
  data(){
    return{
      title:'',
      dialog: false,
      loading: false,
      isLoading: false,
      school_accounts: [],
      userSearch: null,
      account_holder_profile:{
        title:'',
        first_name:'',
        middle_name:'',
        last_name:'',
        role:'',
        phone:'',
        mobile:'',
        work_phone:'',
        is_active:true,
        auto_generate:true,
        new_password:'',
        send_email:false,
        group_email:[],
        current_score_id:'',
        notification_preference:'email',
      },
      emails:[],
      roleSelected:[],
      errors:[],
      countries:[],
      states:[],
      titles:[
        {name: 'None', value: ''},
        {name: 'Ms', value: 'Ms'},
        {name: 'Miss', value: 'Miss'},
        {name: 'Mrs', value: 'Mrs'},
        {name: 'Mr', value: 'Mr'},
        {name: 'Mx', value: 'Mx'},
        {name: 'Dr', value: 'Dr'},
        {name: 'Prof', value: 'Prof'},
      ],
      duplciateEmailStatus:'',
      messages:'',
      buttonDisabled:false,
      showPassword:false,
      scores:[],
      currentUser:null,
    }
  },
  watch: {
    userSearch (val) {
      this.isLoading = true;
      accountholder
          .getAllByUserType(val,'')
          .then((response) => {
            response.data.accountholders.map(user => {
              let data=user;
              data.display_text=user.first_name+' '+ user.last_name;
              this.school_accounts.push(data);
            });
          })
          .catch(err => {
          })
          .finally(() => (this.isLoading = false))
    }
  },
  methods: {
    resetUserSearch() {
      this.userSearch = null;
    },

    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset()
    },
    openDialog() {
      this.dialog = true;
    },
    resetForm(){
      this.account_holder_profile={
        title:'',
        first_name:'',
        middle_name:'',
        last_name:'',
        role:'',
        phone:'',
        mobile:'',
        work_phone:'',
        is_active:true,
        auto_generate:true,
        send_email:false,
        new_password:'',
        notification_preference:'email',
      };
      this.emails=[];
      this.showPassword=false;
      this.buttonDisabled=false;
    },
    createUserAddress() {
      this.getCurrentUser();
      this.resetForm();
      this.openDialog();
      this.getAllScore();

      this.title = "Add new ";
    },

    createAccountholder() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this._create();
      }
    },
    _create: function () {
      if(this.roleSelected.length > 0){
        this.account_holder_profile.role=this.roleSelected.toString();
      }
      if(this.account_holder_profile.send_email && this.emails.length > 0){
        this.account_holder_profile.group_email=this.emails;
      }
      if(this.currentUser && this.currentUser.access_type=='score'){
        this.account_holder_profile.current_score_id=this.currentUser.score_id;
      }
     this.loading=true;
      accountholder
          .create(this.account_holder_profile)
          .then(response => {
            this.$snotify.success("Enroller added");
            this.closeDialog();
            this.resetForm();
            this.emails=[];
            this.loading=false;
            this.errors=[];
            this.loading = false;
            this.account_holder_profile.auto_generate = true;
            this.account_holder_profile.send_email=true
            this.$emit('refresh',response.data.accounholderId);
          })
          .catch(error => {
            this.errors = error.errors;
            this.loading=false;
            this.$snotify.error("Something went wrong");
          })
    },
    checkDuplicateEmail(){
      accountholder
      .checkDuplicateEmail(this.account_holder_profile)
          .then(response => {
            this.messages=response.data.message;
            this.duplciateEmailStatus=response.data.status;
            if(this.duplciateEmailStatus=="SUCCESS"){
              this.buttonDisabled=false;
              this.emails = this.account_holder_profile.email;
            }else{
              this.buttonDisabled=true;
            }
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          })
          .finally(() => {

        })
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    copyEmailAddress() {
      this.account_holder_profile.emails = this.account_holder_profile.email;
    },
  },
  computed:{

  },
  mounted() {

  }
}
</script>