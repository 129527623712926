<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Enrollers</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Enrollers
                </div>
              </div>
              <div class="breadcrumb-right">

                <v-btn  @click="createAccountholder()"  class="btn btn-primary  mr-5" style="color: #fff">
                  <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                  Add enroller
                </v-btn>
                <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="addFilter()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-filter"></i>
                                </span>
                        <span class="navi-text">Filters</span>
                      </a>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="exportToExcel()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-excel"></i>
                                </span>
                        <span class="navi-text">Export (excel)</span>
                      </a>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="exportToCsv()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                        <span class="navi-text">Export (CSV)</span>
                      </a>
                    </b-dropdown-text>

                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="showSelectField()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-database"></i>
                                </span>
                        <span class="navi-text">Export fields</span>
                      </a>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="customFilters.length > 0" class="mb-7">
              <a href="javascript:;" @click="showSavedSearch = !showSavedSearch" class="btn btn-primary text-white"> <i class="fa fa-info"></i> Saved search </a>
              <div class="mt-2" v-if="showSavedSearch">
                <span v-for="(item, idx) in customFilters" class="d-block">
                  <i class="fa fa-clock-o"></i> {{item.created_at_formatted}}, 
                  <strong>Type: </strong> {{item.is_private ? 'Personal' : 'All'}}, 
                  <strong>Name : </strong> <a href="javascript:;" @click="openSavedSearch(item.id)" class="font-weight-bold"> {{item.name}} </a>
                  <a href="javascript:;" @click="deleteCustomFilter(item.id)" class="ml-3"> <i class="fa fa-trash blue--text"></i> </a>
                </span>
              </div>
            </div>

            <div class="search-wrapper border-outline mb-5 px-3 py-5 ">
              <div class="row">
                <v-col cols="12" sm="6" md="3" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      label="SCORE"
                      v-model="search.current_score_id"
                      v-on:keyup.enter="searchAccountholder"
                      @input="search.current_score_id = $event !== null ? $event : ''"
                      item-text="name"
                      item-value="id"
                      :items="scores"
                      outlined
                      dense
                      clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchAccountholder"
                      @input="search.info = $event !== null ? $event : ''"
                      label="Search by name, UID, email, or phone"
                      v-model="search.info" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="">
                  <v-text-field
                      v-on:keyup.enter="searchAccountholder"
                      @input="search.first_name = $event !== null ? $event : ''"
                      label="First name"
                      v-model="search.first_name" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchAccountholder"
                      @input="search.last_name = $event !== null ? $event : ''"
                      label="Surname"
                      v-model="search.last_name" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchAccountholder"
                      @input="search.email = $event !== null ? $event : ''"
                      label="Email"
                      v-model="search.email" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchAccountholder"
                      @input="search.mobile = $event !== null ? $event : ''"
                      label="Mobile"
                      v-model="search.mobile" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                    outlined
                    dense
                    :items="schoolPayStatuses"
                    item-text="title"
                    item-value="value"
                    label="SchoolPay"
                    clearable
                    v-on:keyup.enter="searchAccountholder"
                    @input="search.school_pay_status = $event !== null ? $event : ''"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchAccountholder"
                      dense clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" :md="currentUser.access_type == 'score' ? 3 : 12" class="text-right">
                  <v-btn :loading="isLoading"
                         @click="searchAccountholder"
                         class="btn btn-primary btn-search"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>

                <v-col v-if="this.filters[0].search_fields != ''" cols="12" md="2">
                  <v-btn
                      @click="saveLink('private')"
                      class="btn btn-block btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-save</v-icon>&nbsp;
                    Save for me
                  </v-btn>
                </v-col>

                <v-col v-if="this.filters[0].search_fields != ''" cols="12" md="2">
                  <v-btn
                      @click="saveLink('all')"
                      class="mt-1 btn btn-block btn-primary"
                      style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-save</v-icon>&nbsp;
                    Save for all
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="row mb-2" v-if="search.search_fields.length != 0">
              <v-col cols="12" md="12">
                <div class="subtitle font-weight-bold">
                  Filter applied
                </div>
              </v-col>
              <v-col cols="12" md="10">
                <div class="w-full">
                      <v-btn class="mr-2 mb-2" color="primary"
                          v-for="(item, index) in search.search_fields"
                          v-if="item"
                          :key="index"
                      >
                        {{ formatFilterName(item)}}
                      </v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="2" class="text-right">
                <v-btn
                    @click="resetAppliedFilter"
                    color="black"
                    outlined
                >Reset filter
                </v-btn>
              </v-col>
            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table"  v-if="!isLoading">
                <thead>
                <tr class="text-left">
                  <th>Surname</th>
                  <th>First name</th>
                  <th>UniqueID</th>
                  <th>Contact</th>
                  <th>SCORE</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="accountholders.length > 0" v-for="item in accountholders">
                    <td>

                      <a href="#!" @click.prevent="openQuickView(item.id)" class="mb-1">
                        {{item.last_name }}
                      </a>
                      <div>
                        <!--                        <span class="badge badge-role " v-if="item.role_text">-->
                        <!--                          {{ item.role_text}}-->
                        <!--                        </span>-->
                        <span class="badge "
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.status_text }}</span>
                        <span class="badge badge-info">
                          {{ item.total_candidate}}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span class="mb-1" v-copy="item.first_name">
                        {{item.first_name }}
                      </span>
                    </td>
                    <td>
                      <span  class="" v-copy="item.unique_identifier">
                        {{item.unique_identifier ? item.unique_identifier : 'NA' }}
                      </span>
                    </td>
                    <td>
                      <a class="text-info mb-1" :href="'mailto:'+item.email">
                        {{item.email ? item.email : 'NA' }}
                      </a><br/>
                      <a  class="text-info mb-1" :href="'tel:'+item.preferred_phone">
                        {{item.preferred_phone}}
                      </a>
                    </td>
                    <td>

                        {{item.score_short_name ? item.score_short_name : 'NA' }}

                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click.prevent="openQuickView(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-eye"></i>
                                </span>
                                <span class="navi-text">Quick view</span>

                              </a>
                            </b-dropdown-text>


                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editAccountHolder(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-edit"></i>
                                </span>
                                <span class="navi-text">View profile</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="openResetPassword(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-lock-open"></i>
                                </span>
                                <span class="navi-text">Reset password</span>
                              </a>
                            </b-dropdown-text>


                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="loginAsAccountHolder(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-user-lock"></i>
                                </span>
                                <span class="navi-text">Log in as enroller</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('account_holder','delete')">
                              <a  @click="deleteAccountholder(item.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete profile</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="accountholders.length == 0">
                    <td colspan="7" class="text-center">
                      <strong>No enrollers available</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  :disabled="isLoading"
                  v-if="accountholders.length > 0"
                  class="pull-right mt-7"
                  @input="getAllAccountholder"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-accountholder ref="create-accountholder" @refresh="refreshAfterSave"></create-accountholder>
      <Export ref="export-user"></Export>

      <v-dialog v-model="quickViewDialog" width="600px">
        <v-card>
          <div class="card-body ">
            <!--begin::Toolbar-->
            <div v-if="loading">
              <v-row>
                <v-col cols="4">
                  <v-skeleton-loader
                      v-bind="attrs"
                      class="mx-auto"
                      type="card"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
            <div v-else>

              <!--end::Toolbar-->

              <!--begin::User-->
              <div class="row">
                <div class="float-left pl-5 mb-3">
                  <div  class="font-weight-bold font-size-h5 mb-3">
                    {{accountholderDetail.title ? accountholderDetail.title : ''}} {{accountholderDetail.first_name ? accountholderDetail.first_name : ''}} {{accountholderDetail.middle_name ? accountholderDetail.middle_name : ''}} {{accountholderDetail.last_name ? accountholderDetail.last_name : ''}}

                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong>Email: </strong>  {{accountholderDetail.email}}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong>Preferred phone: </strong>  {{accountholderDetail.preferred_phone}}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong>Enrolled SCORE: </strong>  {{accountholderDetail.score_short_name}}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong>Login verified: </strong>  <span class="badge" v-bind:class="{ 'badge-success': accountholderDetail.is_login_verified, 'badge-danger': !accountholderDetail.is_login_verified }">{{ accountholderDetail.is_login_verified ? 'Yes' : 'No' }}</span>
                  </div>
                  <div class="font-weight-regular mb-1">
                    <strong>Account status: </strong>
                    <span class="badge" v-bind:class="{ 'badge-success': accountholderDetail.is_login_verified, 'badge-danger': !accountholderDetail.is_active }">{{ accountholderDetail.is_active ? 'Active' : 'Inactive' }}</span>
                  </div>

                  <div class="font-weight-regular mb-1">
                    <strong>Phone: </strong>  {{accountholderDetail.phone ? accountholderDetail.phone :'NA'}}
                  </div>

                  <div class="font-weight-regular mb-1">
                    <strong>Mobile: </strong>  {{accountholderDetail.mobile ? accountholderDetail.mobile :'NA'}}
                  </div>


                  <div class="font-weight-regular mb-1">
                    <strong>Work: </strong>  {{accountholderDetail.work ? accountholderDetail.work :'NA'}}

                  </div>
                  <div class="font-weight-regular mb-1">
                    <strong>Notification method: </strong>  {{accountholderDetail.notification_preference ? accountholderDetail.notification_preference :'NA'}}

                  </div>


                </div>

                <div class="float-right col-md-6 mt-2 pl-6">
                    <div style="min-height: 200px">
                      <div class="col-md-12" v-if="accountholderDetail.shipping_address">
                        <h4 class="font-weight-bolder text-dark">Postal address</h4>
                        <div href="#" class="text-dark-75 font-weight-medium font-size-lg mb-1">
                          {{accountholderDetail.shipping_address.address_line_1}} <br>
                          {{accountholderDetail.shipping_address.suburb ? accountholderDetail.shipping_address.suburb : 'NA'}}, {{accountholderDetail.shipping_address.country_id == 14 ? accountholderDetail.shipping_address.state_name : accountholderDetail.shipping_address.state}},{{accountholderDetail.shipping_address.post_code ? accountholderDetail.shipping_address.post_code : 'NA'}} <br>
                          {{accountholderDetail.shipping_address.country_name ? accountholderDetail.shipping_address.country_name : 'NA'}}
                        </div>
                        <!--end::Content-->
                      </div>

                      <div class="col-md-12" v-if="accountholderDetail.billing_address">
                        <h4 class="font-weight-bolder text-dark">Billing address</h4>
                        <div href="#" class="text-dark-75 font-weight-medium font-size-lg mb-1">
                          {{accountholderDetail.billing_address.address_line_1}} <br>
                          {{accountholderDetail.billing_address.suburb ? accountholderDetail.billing_address.suburb : 'NA'}}, {{accountholderDetail.billing_address.country_id == 14 ? accountholderDetail.billing_address.state_name : accountholderDetail.billing_address.state}},{{accountholderDetail.billing_address.post_code ? accountholderDetail.billing_address.post_code : 'NA'}} <br>
                          {{accountholderDetail.billing_address.country_name ? accountholderDetail.billing_address.country_name : 'NA'}}
                        </div>

                    <!--end::Content-->
                  </div>

                    </div>
                  <div class="md-6 text-dark-50 font-weight-bold mt-3">
                    <i class="flaticon-calendar-with-a-clock-time-tools mr-2 font-weight-regular"></i>
                    <b>Member since:</b> {{accountholderDetail.member_since}}</div>
                </div>


              </div>
              <!--end::User-->
              <div class="separator separator-solid"></div>
              <!--begin::Contact-->

              <div class="separator separator-solid"></div>
              <div class="pb-6">
                <div class="d-flex align-items-center flex-wrap mt-8">
                  <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                     <span class="mr-4"><i class="flaticon2-open-text-book display-2 text-muted font-weight-bold"></i>
                    </span>
                    <a class="d-flex flex-column text-dark-75"  @click="editAccountHolder(accountholderDetail.id,3)">
                      <span class="font-weight-bolder font-size-sm">Theory exams</span>
                      <span class="font-weight-bolder font-size-h5">{{accountholderDetail.total_exam_candidate}}</span>
                    </a>
                  </div>
                  <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                     <span class="mr-4"><i class="flaticon-interface-3 display-2 text-muted font-weight-bold"></i>
                    </span>
                    <a class="d-flex flex-column text-dark-75" @click="editAccountHolder(accountholderDetail.id,2)">
                      <span class="font-weight-bolder font-size-sm">Practical exams</span>
                      <span class="font-weight-bolder font-size-h5">{{accountholderDetail.total_practical_candidate}}</span>
                    </a>
                  </div>
                  <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                     <span class="mr-4"><i class="flaticon-file-1 display-2 text-muted font-weight-bold"></i>
                    </span>
                    <a class="d-flex flex-column text-dark-75" @click="editAccountHolder(accountholderDetail.id,4)">
                      <span class="font-weight-bolder font-size-sm">Theory courses</span>
                      <span class="font-weight-bolder font-size-h5">{{accountholderDetail.total_course_candidate}}</span>
                    </a>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 text-right">
                  <v-btn @click="editAccountHolder(accountholderDetail.id)" class="pull-right"
                         dark x-large>
                    Go to profile
                  </v-btn>
                </div>
              </div>

            </div>

          </div>

        </v-card>
      </v-dialog>
      <v-dialog v-model="resetPasswordDialog" max-width="500">
        <v-card>
          <v-card-title>
            Reset password
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>

                <v-col cols="12" sm="6" md="12">
                  <v-switch
                      v-model="reset.auto_generate"
                      :label="`Auto generate password`"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6" md="12" v-if="!reset.auto_generate">
                  <v-text-field
                      label="Enter password*" v-model="reset.new_password"
                      dense outlined  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                  ></v-text-field>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text
                  x-large
                  @click="closeResetPasswordDialog">
                Cancel
              </v-btn>
              <v-btn
                  dark
                  x-large
                  @click="resetPassword">
                Reset password
              </v-btn>

            </v-card-actions>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          scrollable
          v-model="searchFieldDialog"
          width="800px"
      >
        <v-card>
          <v-card-title>
            Search filters
          </v-card-title>
          <v-card-text>
            <v-row class="mt-2" v-for="(row, index) in filters" :key="index">
              <v-col cols="12" md="3">
                <v-select
                    v-model="row.search_fields"
                    :items="accountHolderFiltersArray"
                    item-text="text"
                    item-value="value"
                    label="Column"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                    :items="conditionFilters"
                    v-model="row.search_conditions"
                    item-text="text"
                    item-value="value"
                    label="Condition"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                    label="Value"
                    v-model="row.search_values"
                    outlined
                    dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                    v-if="index == filters.length-1"
                    color="blue"
                    class="text-white mr-2"
                    medium
                    @click="addFilterColumn()"
                >
                  <i class="fa fa-plus font-white"></i>
                </v-btn>
                <v-btn
                    v-if="index || (!index && filters.length > 1)"
                    @click="removeFilterColumn(index)"
                    color="red"
                    class=" text-white"
                    medium
                >
                  <i class="fa fa-times font-white"></i>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-content-end">
            <v-btn
                @click="closeSelectFieldDialog"
                text
                x-large
            >
              Cancel
            </v-btn>
            <v-btn
                @click="applyFilter"
                x-large
                dark
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          scrollable
          v-model="selectFieldDialog"
          width="700px"
      >
        <v-card>
          <v-card-title>
            Select fields that should appear on the export
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-for="(item, index) in accountHolderFilters" cols="12" md="4">
                <v-checkbox
                    :label="index"
                    :value="item"
                    v-model="selectedFields"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>

          <v-spacer></v-spacer>

          <v-card-actions class="d-flex justify-content-end">

            <v-btn
                @click="closeExcelSelectFieldDialog"
                class="cancel-btn"
                text
                x-large
            >
              Cancel
            </v-btn>
            <v-btn
                @click="selectFieldDialog = false;"
                class="btn btn-primary"
                dark
                x-large
            >
              Select
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>

      <!-- save search result popup -->
      <v-dialog
          scrollable
          v-model="showSaveDialog"
          width="800px"
      >
        <v-card>
          <v-card-title>
            Save search filter
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  class="mt-3"
                  outlined
                  dense
                  placeholder="Save search with name"
                  v-model="custom_filter.name"
                  :error="$v.custom_filter.name.$error"
                >
                  <template v-slot:label>
                    Name <span class="text-danger">*</span>
                  </template>
                </v-text-field>
                <span class="text-danger" v-if="$v.custom_filter.name.$error">
                  This information is required
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-spacer></v-spacer>

          <v-card-actions class="d-flex justify-content-end">

            <v-btn
              class="cancel-btn"
              @click="cancelSaveSearch"
              text
              x-large
            >
              Cancel
            </v-btn>
            <v-btn
                :loading="saveLinkLoading"
                class="btn btn-primary text-white"
                @click="saveType == 'private' ? saveLinkForMe() : saveLinkForAll()"
                x-large
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- save search result popup ends -->

    </div>
  </v-app>
</template>
<script>

import AccountholderService from "@/services/user/accountholder/AccountholderService";
import CreateAccountholder from "./CreateAccountholder";
import Export from "./Export";
import ScoreService from "@/services/score/score/ScoreService";
import CustomFilterLinkService from "@/services/custom-filter-link/CustomFilterLinkService";

import {requiredIf, required} from "vuelidate/lib/validators";

const accountholder=new AccountholderService();
const score = new ScoreService();
const customFilterLink = new CustomFilterLinkService();

export default {
  components:{
    CreateAccountholder, Export
  },
  validations:{
    reset:{
      required: requiredIf(function (nestedModel) {
        return this.reset.auto_generate==false;
      })
    },
    custom_filter:{
      name: {required}
    }
  },
  data(){
    return{
      isLoading:false,
      loading: true,
      attrs: [],
      isExporting:false,
      isExportingExcel: false,
      search:{
        info:'',
        first_name:'',
        last_name:'',
        is_active:'',
        current_score_id:'',
        type:'',
        mobile: '',
        email: '',
        selectedFields: [],
        search_fields: [],
        search_conditions: [],
        search_values: [],
        is_search:false,
        school_pay_status: ''
      },
      selectedFields: [],
      selectedFilters: [],
      filters:[{
        search_fields:'',
        search_conditions:'',
        search_values:'',
      }],
      rows: [],
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      conditionFilters: [
        {text: 'Equals To', value: 'equals_to'},
        {text: 'Less Than', value: 'less_than'},
        {text: 'Less Than Equals To', value: 'less_than_equals_to'},
        {text: 'Greater Than', value: 'greater_than'},
        {text: 'Greater Than Equals To', value: 'greater_than_equals_to'},
        {text: 'Contains', value: 'contains'},
      ],
      selectFieldDialog: false,
      scores: [],
      quickViewDialog:false,
      resetPasswordDialog:false,
      accountholders:[],
      accountholderDetail:{},
      total: null,
      perPage: null,
      page: null,
      reset:{
        accountholderId:'',
        auto_generate:true,
        new_password:'',
      },
      showPassword:false,
      searchFieldDialog: false,
      accountHolderFilters: [],
      accountHolderFiltersArray: [],
      customFilters: '',
      saveLinkLoading: false,
      showSavedSearch: false,
      showSaveDialog: false,
      saveType: '',
      custom_filter:{
        name: ''
      },
      schoolPayStatuses:[
        {
          title: 'Active',
          value: 'active'
        },
        {
          title: 'No account',
          value: 'no_account'
        }
      ]
    }
  },
  methods:{
    exportAccountHolder(){
      this.$refs['export-user'].exportUser();
    },
    openQuickView(itemId){
      this.quickViewDialog=true;
      this.getAccountholderDetail(itemId);
    },
    getAccountholderDetail(itemId){
      this.loading=true
      accountholder
          .show(itemId)
          .then(response => {
            this.accountholderDetail=response.data.accountHolder;
            this.loading = false;
          }).
      finally(() => {
        this.loading = false;
      })
    },
    getAllAccountholder(){
      this.isLoading = true;
      if(this.$route.params &&  this.$route.params.search){
        this.search['id']=this.$route.params.search;
      }
      accountholder
          .paginate(this.search,this.page)
          .then(response => {
            this.accountholders=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {
            this.$snotify.error('Oops looks like something went wrong.');
            this.searchFieldDialog=false;
            this.isLoading = false;
          });
    },
    applyFilter(){
      this.formatExportData();
      this.getAllAccountholder();
      this.searchFieldDialog=false;
    },
    formatExportData(){
      this.search.selectedFields = this.selectedFields;
      let search_conditions =[];
      let search_fields = [];
      let search_values = [];

      this.filters.forEach((filter)=>{
        if(filter.search_conditions)
          search_conditions.push(filter.search_conditions);
        if(filter.search_fields)
          search_fields.push(filter.search_fields);
        if(filter.search_values)
          search_values.push(filter.search_values);
      });

      this.search.search_conditions = search_conditions;
      this.search.search_fields = search_fields;
      this.search.search_values = search_values;
    },
    exportToCsv(){
      this.isExporting = true;
      this.formatExportData();

      accountholder
          .exportToCsv(this.search)
          .then(response => {
            this.$snotify.success('Export added to queue. You will receive an email upon completion');
            this.selectedFields = [];
            this.search.selectedFields = [];
          })
          .catch(error => {

          })
          .finally(() => {
            this.isExporting = false;
            this.closeSelectFieldDialog();
          })
    },
    exportToExcel(){
      this.isExportingExcel = true;
      this.formatExportData();

      accountholder
          .exportToExcel(this.search)
          .then(response => {
            this.$snotify.success('Export added to queue. You will receive an email upon completion');
            this.selectedFields = [];
            this.search.selectedFields = [];
          })
          .catch(error => {

          })
          .finally(() => {
            this.isExportingExcel = false;
            this.closeSelectFieldDialog();
          })
    },
    refreshAfterSave(payload){
      if(payload){
        this.$router.push({
          name: "accountholder-edit",
          params: { accountholderId: payload }
        });
      }
    },
    searchAccountholder(){
      this.page=1;
      this.search.is_search = true;
      this.getAllAccountholder();
    },
    deleteAccountholder(item){
      this.$confirm({
        message: `Are you sure you want to delete this account? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            accountholder
                .delete(item)
                .then((response) => {
                  this.getAllAccountholder();
                  this.$snotify.success("Enroller deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    editAccountHolder(accountholderId, tabIndex=0){
      this.$tabs.open({
        name: "accountholder-edit",
        params: { accountholderId: accountholderId, tabIndex:tabIndex }
      });
    },

    loginAsAccountHolder(accountHolderId)
    {
      accountholder.loginAsAccountHolder(accountHolderId).then(response=>{
        if(response.data.status=='OK')
          window.open(response.data.url,'_blank')
        else
          this.$snotify.error("Oops something went wrong");
      }).catch(error=>{

      });
    },
    createAccountholder(){
      this.$refs['create-accountholder'].createUserAddress();
    },
    openResetPassword(accountholderId){
      this.resetPasswordDialog=true;
      this.reset.accountholderId=accountholderId;
    },
    closeResetPasswordDialog(){
      this.resetPasswordDialog=false;
      this.reset.accountholderId='';
      this.reset.auto_generate=true;
      this.reset.new_password='';
    },
    getAllScore() {
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          });
    },
    deactivateAccountHolder(id){
      this.$confirm({
        message: `Are you sure you want to deactivate this enroller? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            accountholder
                .deactivateAccount(id)
                .then((response) => {
                  this.getAllAccountholder();
                  this.$snotify.success("Enroller login deactivated");
                })
          }
        }
      })
    },
    resetPassword(){
      this.$v.reset.$touch()
      if(this.$v.reset.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      }
      else {
        this.$confirm({
          message: `Are you sure you  want to reset password for this user? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              accountholder
                  .resetPassword(this.reset.accountholderId, this.reset)
                  .then((response) => {
                    this.closeResetPasswordDialog();
                    this.$snotify.success("New password emailed to enroler");
                  })
                  .catch((err) => {
                    this.$snotify.error("Oops something went wrong");
                  });
            }
          }
        })
      }
    },
    showSelectField(){
      this.selectFieldDialog = true;
    },
    addFilter(){
      this.searchFieldDialog = true;
    },
    closeSelectFieldDialog(){
      this.filters = [{
        search_fields:'',
        search_conditions:'',
        search_values:'',
      }];
      this.search = {
        selectedFields: [],
        search_fields: [],
        search_conditions: [],
        search_values: [],
      }
      this.searchFieldDialog = false;
      this.getAllAccountholder();
    },
    closeExcelSelectFieldDialog(){
      this.selectFieldDialog = false;
      this.search.selectedFields = [];
      this.selectedFields = [];
    },
    addFilterColumn(){
      this.filters.push({
        search_fields:'',
        search_condition:'',
        search_values:'',
      });
    },
    removeFilterColumn(index){
      this.filters.splice(index, 1);
    },
    getExportFilters(){
      accountholder
          .getExportFilters()
          .then((response) => {
            this.accountHolderFilters = response.data.accountHolderFilters;
            let accountFilters = response.data.accountHolderFilters;
            accountFilters =  Object.entries(accountFilters);

            accountFilters.forEach(([key, value]) => {
              let data = {
                text: key,
                value: value
              };
              this.accountHolderFiltersArray.push(data);
            });
          }).catch((err) => {

      });
    },
    formatFilterName(name){
      if(name){
        name = name.replace("users^is_active","Status");
        name = name.replace("users^","");
        name = name.replace("scores^name","SCORE");
        name = name.replace("user_settings^","");

        name = name.replace("user_addresses^shipping^address_line_1","Postal address - address line 1");
        name = name.replace("user_addresses^shipping^address_line_2","Postal address - address line 2");
        name = name.replace("user_addresses^shipping^suburb","Postal address - suburb");
        name = name.replace("states^shipping^name","Postal address - state");
        name = name.replace("user_addresses^shipping^post_code","Postal address - post code");
        name = name.replace("countries^shipping^name","Postal address - country");

        name = name.replace("user_addresses^billing^address_line_1","Billing address - address line 1");
        name = name.replace("user_addresses^billing^address_line_2","Billing address - address line 2");
        name = name.replace("user_addresses^billing^suburb","Billing address - suburb");
        name = name.replace("states^billing^name","Billing address - state");
        name = name.replace("user_addresses^billing^post_code","Billing address - post code");
        name = name.replace("countries^billing^name","Billing address - country");
        
        name = name.replace(/_/g," ");
        return name.charAt(0).toUpperCase()+name.slice(1)
      }
    },
    resetAppliedFilter(){
      this.filters = [{
        search_fields:'',
        search_conditions:'',
        search_values:'',
      }];

      this.search.search_fields = [];
      this.search.search_values = [];
      this.search.search_conditions = [];

      this.applyFilter();
    },
    saveLink(type){
      this.saveType = type;
      this.showSaveDialog = true;
    },
    saveLinkForMe(){
      this.$v.custom_filter.$touch()
      if(this.$v.custom_filter.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      }
      else {
        this.saveLinkLoading = true;

        if(this.$route.params &&  this.$route.params.search){
          this.search['id']=this.$route.params.search;
        }
        
        let completeUrl = accountholder.getCompleteSearchUrl(this.search,this.page)
        let saveLinkData = {
          name: this.custom_filter.name,
          type: 'accountholder',
          is_private: 1,
          url : completeUrl,
          query_params: this.search,
          export_fields: this.selectedFields,
          user_id: this.currentUser.id,
          score_id: this.currentUser.access_type == 'score' ? this.currentUser.score_id : ''
        }
        this.saveSearchUrl(saveLinkData);
      }
    },

    saveLinkForAll(){
      this.$v.custom_filter.$touch()
      if(this.$v.custom_filter.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      }
      else {
        this.saveLinkLoading = true;

        if(this.$route.params &&  this.$route.params.search){
          this.search['id']=this.$route.params.search;
        }
        
        let completeUrl = accountholder.getCompleteSearchUrl(this.search,this.page);
        
        let saveLinkData = {
          name: this.custom_filter.name,
          type: 'accountholder',
          is_private: 0,
          url : completeUrl,
          query_params: this.search,
          export_fields: this.selectedFields,
          user_id: this.currentUser.id,
          score_id: this.currentUser.access_type == 'score' ? this.currentUser.score_id : ''
        }

        this.saveSearchUrl(saveLinkData);
      }
    },
    saveSearchUrl(data){
      
      customFilterLink
      .store(data)
      .then((res) => {
        this.$snotify.success('Search saved')
      })
      .catch((err) => {
        this.$snotify.error("Oops something went wrong");
      })
      .finally(() => {
        this.saveLinkLoading = false;
        this.cancelSaveSearch();
        this.getAllCustomFilterLinks();
      })
    },
    cancelSaveSearch(){
      this.showSaveDialog = false;
      this.custom_filter = {
        name: ''
      };
    },
    getAllCustomFilterLinks(){
      
      let filter = {
        type: 'accountholder',
      };

      customFilterLink
      .all(filter)
      .then((res) => {
        this.customFilters = res.data.data;
      })
      .catch((err) => {
        
      })
    },
    deleteCustomFilter(searchId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            customFilterLink
            .delete(searchId)
            .then((response) => {
              this.getAllCustomFilterLinks();
              this.$snotify.success("Filter deleted");
            })
            .catch((err) => {
              this.$snotify.error("Something went wrong");
            });
          }
        }
      })
    },
    openSavedSearch(searchId){
      this.resetAppliedFilter();

      customFilterLink
      .show(searchId)
      .then((res)=>{        
        this.filters = res.data.data.formatted_query_params;
        this.selectedFields = res.data.data.export_fields_formatted;
        this.applyFilter();
      })
      .catch((err) => {

      });
    }
  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  mounted() {
    this.getAllAccountholder();
    this.getAllScore();
    this.getExportFilters();
    this.getAllCustomFilterLinks();
  }
}
</script>
